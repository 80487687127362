#from-design {
  .progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 90%;
    margin: auto;
    transform: translate(0, -50%);
    &:before {
      content: ""; /* Mandatory with ::before */
      background-color: #979797;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      z-index: -1;
    }

    @media (min-width: 600px) {
      margin-top: 8px;
      max-width: 432px;
    }

    .progress {
      background-color: #979797;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 1px;
      width: 0%;
      z-index: -1;
      transition: 0.4s ease;
    }
    .circle {
      background-color: #fff;
      color: #979797;
      border-radius: 50%;
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #979797;
      transition: 0.4s ease;

      @media (min-width: 600px) {
        width: 13.5px;
        height: 13.5px;
      }

      span {
        position: absolute;
        top: 14px;
        color: #979797;
        font-size: 10px;
        text-transform: uppercase;

        @media (min-width: 600px) {
          font-size: 12px;
          top: 20px;
        }
      }
    }

    .circle.active {
      background-color: #000000;
      border-color: #000000;
      span {
        color: #000000;
      }
    }

    .done {
      background-color: #979797;
      border-color: #979797;
    }
  }

  .line {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 18px;

    @media (min-width: 600px) {
      margin-top: 26px;
      margin-bottom: 32px;
    }

    > div {
      width: 134px;
      border-bottom: 1px solid #000;
    }
  }

  .line--commitment {
    margin-top: 0;
    margin-bottom: 20px;

    @media (min-width: 600px) {
      margin-top: 12px;
      margin-bottom: 32px;
    }
  }

  .line--design {
    > div {
      width: 134px;
      border-bottom: 1px solid #979797;
    }
  }

  .line-desktop {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .back-to-top {
    font-family: $textFontBold;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }

  .faq-table {
    width: 350px;
    font-size: 12px;
    text-align: center;
    margin: 0 auto;
    font-family: $textFontBold;
    margin-top: 1.5rem;

    .thead,
    .item {
      display: flex;
    }

    .thead {
      background-color: #e6e6e6;
      height: 27px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .category {
        width: 170px;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .retail-price,
      .artist-price {
        width: 90px;
      }
    }

    .item {
      > div {
        border-right: 1px solid #e6e6e6;
        padding: 4px 5px;
      }
      .category {
        width: 170px;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }

      .retail-price,
      .artist-price {
        width: 90px;
      }
    }
    .item:last-child {
      background-color: #e6e6e6;
      height: 27px;
    }

    @media (max-width: 600px) {
      max-width: 246px;
      font-family: $textFont;
      margin: 1.5rem -20px 0;

      .thead {
        background-color: #e6e6e6;
        height: unset;

        > div {
          padding: 6px;
        }

        .category {
          width: 116px;
        }

        .retail-price,
        .artist-price {
          width: 65px;
        }
      }

      .item {
        .category {
          width: 116px;
          padding-left: 5px;
        }

        .retail-price,
        .artist-price {
          width: 65px;
        }
      }
    }
  }

  .step {
    text-align: center;
    margin-top: 36px;

    p {
      margin-bottom: 8px;
    }

    .step__name {
      font-size: 10px;
      text-transform: uppercase;

      @media (min-width: 600px) {
        font-size: 14px;
      }
    }

    .step__description {
      font-size: 12px;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      @media (min-width: 600px) {
        font-size: 16px;

        p {
          margin-bottom: 20px;
        }
      }

      > div {
        width: 206px;
        @media (min-width: 600px) {
          width: unset;
          max-width: 800px;
        }
      }
    }
  }
  .step__title {
    @media (min-width: 600px) {
      font-size: 36px;
    }
  }

  .step--shape {
    .row.row--custom {
      margin: 0 -7px;
      @media (min-width: 600px) {
        margin: 0 -11px;
        max-width: 660px;
        margin: auto;
      }

      .col--custom {
        padding: 7px;
        @media (min-width: 600px) {
          padding: 11px;
        }

        .card {
          border-color: #979797;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          .card__img {
            width: 100%;
            object-position: center;
            object-fit: cover;
          }

          .card__package-name {
            height: 47px;
            font-size: 14px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:hover {
            .card__package-name {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .step--artist {
    .step__description {
      @media (min-width: 600px) {
        font-size: 16px;

        p {
          margin-bottom: 20px;
        }
      }

      > div {
        @media (min-width: 600px) {
          width: unset;
          max-width: 1024px;
        }
      }
    }
    .btn {
      border: 1px solid #000000;
      color: #000;
      background-color: #fff;
      height: 45px;
      width: 345px;
      min-width: 345px;
      margin-top: 10px;
      font-family: $textFont;
      line-height: 18px;

      &:hover {
        color: #fff;
        background-color: #000;
      }
      &:last-child {
        margin-bottom: 1rem;
      }
    }

    .btn-art-outlet {
      width: 563px;
      min-width: 345px;

      @media (max-width: 600px) {
        width: 100%;
        height: auto;
        padding: 5px;
      }
    }
    
    .modal-lg {
      height: 100vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: initial;
    }

    .overlay {
      position: absolute;
      top: 176px;
      text-align: center;
      width: 100%;
      color: #fff;
      font-family: "CircularStd-Book";
      font-style: normal;
      font-weight: 400;

      .overlay-header {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0.2px;
      }

      .overlay-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-top: 15px;
        margin-bottom: 30px;
      }

      .overlay-btn {
        padding: 15px 30px;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        margin: auto;
        width: unset;
        min-width: unset;
        border-radius: unset;

        &:hover {
          color: #fff;
        }
      }
    }

    .skip-step {
      position: absolute;
      bottom: -60px;
      text-align: center;
      color: #fff;
      width: 100%;
      text-decoration-line: underline;
      font-size: 14px;
      line-height: 18px;

      a {
        cursor: pointer;
      }
    }

    .close-overlay {
      position: absolute;
      top: 20px;
      color: #fff;
      right: 20px;
      font-size: 30px;
      z-index: 99999;
      cursor: pointer;

      &:hover {
        text-decoration-line: unset;
      }
    }
  }

  .step--art {
    .upload-artworks {
      display: flex;
      align-items: center;
      justify-content: center;

      .upload-artworks_content {
        width: 134px;
        .upload {
          width: 134px;
          height: 134px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed #000000;
          border-radius: 8px;
          background-color: #ffffff;
          cursor: pointer;

          &:hover {
            background-color: #000;
            color: #fff;
          }

          svg {
            font-size: 39px;
          }
          input {
            display: none;
          }
        }
        @media (min-width: 600px) {
          width: 155px;

          .upload {
            height: 155px;
            width: 155px;

            svg {
              font-size: 45px;
            }
          }
        }
        .images {
          display: flex;
          margin: 9px -4px 14px;

          .images__item {
            padding: 0 4px;

            > div {
              height: 62px;
              width: 62px;
              border-radius: 8px;
              background-color: #e6e6ea;
              overflow: hidden;
              @media (min-width: 600px) {
                height: 72px;
                width: 72px;
              }

              img {
                overflow: hidden;
                border-radius: 8px;
                height: 100%;
                width: 100%;
              }
            }
          }
        }
        .skip {
          text-decoration: underline;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }

    .popup-upload-file-err {
      height: 100%;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      display: none;

      &.show {
        display: block;
      }

      .top {
        width: 100vw;
        height: 240px;

        .opacity {
          opacity: 0.7;
          background-color: #fff;
          width: 100vw;
          height: 100px;
        }
        .content {
          width: 100vw;
          height: 140px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn-upload-file {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px auto;
            border: 1px solid #000000;
            border-radius: 8px;
            background-color: #000000;
            color: #fff;
            font-size: 12px;
            padding: 8px 10px;
            text-transform: uppercase;
          }
        }
      }
      .bottom {
        width: 100vw;
        height: 100%;
        background-color: #e6e6ea;
        font-size: 12px;
        display: flex;
        justify-content: center;
        display: flex;
        justify-content: center;
        padding: 25px 60px;

        > div {
          width: 200px;
        }
      }

      @media (min-width: 600px) {
        .top {
          height: 100%;

          .opacity {
            opacity: 0.9;
            background-color: #fff;
            width: 100vw;
            height: 124px;
          }

          .content {
            width: 100%;
            height: 380px;
            align-items: start;
            padding: 44px 0;

            > div {
              max-width: 460px;
              font-size: 14px;
            }

            .btn-upload-file {
              margin-top: 26px;
              border: 1px solid #000000;
              border-radius: 8px;
              background-color: #fff;
              color: #000;
              font-size: 16px;
              padding: 8px 14px;
              text-transform: uppercase;
              font-family: $textFontBold;

              &:hover {
                background-color: #000;
                color: #fff;
              }
            }
          }

          .bottom {
            height: calc(100% - 504px);
            background-color: #fff;
            opacity: 0.9;
          }
        }
      }
    }
  }

  .step--info {
    .content {
      display: flex;
      justify-content: center;
      width: 100%;

      form {
        width: 246px;

        label {
          width: 100%;
          font-size: 10px;
          font-family: $textFontBold;
          text-align: left;
          margin-bottom: 4px;
        }

        .artist-info-label {
          margin-top: 0.5rem;
        }

        input {
          width: 100%;
          margin-bottom: 9px;
          box-sizing: border-box;
          height: 30px;
          border: 1px solid #000000;
          border-radius: 8px;
          background-color: #ffffff;
          padding: 3px 12px;
          font-size: 14px;

          &:focus {
            outline: none;
          }
        }

        button {
          height: 30px;
          width: max-content;
          border-radius: 8px;
          background-color: #000000;
          color: #fff;
          font-size: 12px;
          margin-top: 11px;
          margin-bottom: 4px;
          border: none;
        }

        select {
          width: 100%;
          margin-bottom: 9px;
          box-sizing: border-box;
          height: 30px;
          border: 1px solid #000000;
          border-radius: 8px;
          background-color: #ffffff;
          padding: 3px 12px;
          font-size: 14px;

          &:invalid {
            color: #979797;
          }

          &:focus {
            outline: none;
          }
        }

        @media (min-width: 600px) {
          width: 410px;

          .row {
            margin: 0 -5px;
            .col-md-6,
            .col-md-12 {
              padding: 0 5px;
            }
          }

          label {
            font-size: 16px;
          }

          .artist-info-label {
            margin-top: 32px;
          }

          input {
            height: 35px;
            font-size: 16px;
          }

          button {
            height: 35px;
            border-radius: 8px;
            background-color: #fff;
            color: #000;
            font-size: 16px;
            margin-top: 35px;
            padding: 0 12px;
            border: 1px solid #000000;
            font-family: $textFontBold;

            &:hover {
              background-color: #000;
              color: #fff;
            }
          }

          select {
            height: 35px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .step--designs {
    .content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      img {
        width: 100%;
        max-width: 240px;
        max-height: 240px;
        margin: auto;
      }
      > div {
        width: 100%;
        > div {
          width: 206px;
          font-size: 12px;
          margin: auto;
          margin-bottom: 20px;
        }
      }

      .btn-ok {
        height: 30px;
        border-radius: 8px;
        background-color: #000000;
        color: #ffffff;
        font-size: 12px;
        outline: none;
        border: none;
        padding: 7px 10px;
        margin-top: 12px;
      }
      .img-welcome {
        margin-bottom: 20px;
        height: unset;
      }

      @media (min-width: 600px) {
        img {
          max-width: 360px;
          max-height: 360px;
          margin-bottom: 32px;
        }
        .img-welcome {
          max-width: 460px;
          height: unset;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: center;

          > div {
            width: 460px;
            margin-bottom: 36px;
            font-size: 16px;

            p {
              margin-bottom: 32px;
            }

            .btn-ok {
              height: 35px;
              border-radius: 8px;
              background-color: #fff;
              color: #000;
              font-size: 16px;
              padding: 7px 12px;
              border: 1px solid #000000;
              font-family: $textFontBold;
              &:hover {
                background-color: #000;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .commitment {
    margin: 20px -16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e6ea;
    font-size: 12px;

    .content {
      display: block;
      width: 215px;

      li:not(:last-child) {
        padding-bottom: 12px;
      }

      @media (min-width: 600px) {
        width: 500px;
        padding: 28px 0 16px;
      }

      .commitment__title {
        padding: 0 10px;
        text-align: center;

        @media (min-width: 600px) {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }

      .commitment__item {
        display: flex;
        align-content: center;

        @media (min-width: 600px) {
          font-size: 14px;
        }

        img {
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }
    }

    .content--1 {
      width: 250px;

      @media (min-width: 600px) {
        width: 460px;
        font-size: 16px;
      }
    }
  }

  .error {
    color: #cd0200;
    font-size: 11px;
    width: 100%;

    a {
      color: #0c73cd;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

	#email-existed-error, 
	#confirm-email-existed-error {
		position: relative;
		text-align: left;
		top: -5px;
	}
	.popup-upload-choose-info-artwork {
		min-height: 100%;
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		display: none;
    position:absolute;

    &.show {
      display: block;
    }

    .opacity {
      opacity: 0.7;
      background-color: #fff;
      width: 100%;
      height: 100px;
    }
    .content {
      width: 100%;
      height: 100%;
      background-color: #fff;

      .group-enter-name {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          width: 184px;
          display: flex;
          align-items: center;

          input {
            width: 154px;
            font-size: 14px;
            padding: 4px 8px;
            height: 26px;
            border: 1px solid #000000;
            border-radius: 8px 0 0 8px;
            background-color: #ffffff;

            &:focus {
              outline: none;
            }
          }

          button {
            height: 26px;
            width: 30px;
            background-color: #000000;
            border: none;
            border-radius: 0 8px 8px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover:not(:disabled) {
              background-color: #000;
              color: #fff;
            }

            svg {
              font-size: 18px;
              color: #fff;
            }
          }
        }
      }

      .group-images {
        margin-top: 25px;
        img {
          box-sizing: border-box;
          height: 133px;
          width: 133px;
          border-radius: 8px;
          background-color: #d8d8d8;
        }
        .text {
          margin-top: 4px;
          font-size: 12px;
          text-align: center;
          text-decoration: underline;
          cursor: pointer;
        }

        .artwork-name {
          font-size: 12px;
          margin-top: 16px;
          font-family: $titleFont;
        }
      }

      .box {
        width: 100%;
        display: flex;
        justify-content: center;

        > div {
          width: 240px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .item {
            width: 33.3333%;
            padding: 8px;
            cursor: pointer;

            .div-img {
              width: 45px;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              margin: auto;

              > svg {
                width: 26px;
                height: 26px;
              }
            }

            .text {
              width: 100%;
              font-size: 10px;
            }

            &:hover {
              .div-img {
                background-color: #000;

                > svg {
                  g {
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
      }

      //content info modal
      .content-info-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          width: 300px;

          .step__title--info {
            text-align: center;
          }

          .group-image-sharp {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .group-image-sharp__image {
              position: relative;
              width: 122px;
              height: 98px;
              margin-left: -33px;

              .image-1 {
                position: absolute;
                height: 56px;
                top: 2px;
                left: 2px;
                object-fit: cover;
                object-position: center;
              }
              .image-2 {
                height: 73px;
                right: 0;
                position: absolute;
                bottom: 0;
              }
            }
            .group-image-sharp__text {
              margin-left: 15px;
              font-size: 14px;
              line-height: 18px;
              display: none;
              text-align: left;
            }
          }

          .group-image-not-use {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            padding: 0 50px;

            .group-image-not-use__item {
              position: relative;
              width: 50%;
              height: 100%;
              margin-top: 15px;

              .group-image-not-use__item__image {
                width: 100%;
                height: 57px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                .image-1 {
                  position: absolute;
                  height: 57px;
                }
                .image-2 {
                  height: 57px;
                }
              }
              .group-image-not-use__item__text {
                margin-top: 5px;
                width: 100%;
                text-align: center;
                font-size: 14px;
              }
            }
          }

          .btn-i-understand {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 33px;

            > button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #000000;
              background-color: #000;
              font-size: 12px;
              color: #fff;
              padding: 7px 12px;
              border-radius: 8px;
              font-family: $textFontBold;
            }
          }
        }
      }
    }

    @media (min-width: 600px) {
      overflow: hidden;

      .opacity {
        opacity: 0.9;
        background-color: #f5f5f5;
        width: 100vw;
        height: 124px;
      }

      .content {
        width: 100%;
        height: auto;
        padding: 44px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          //max-width: 460px;
          font-size: 14px;

          .group-enter-name {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 17px;

            > div {
              width: 231px;
              display: block;

              input {
                width: 231px;
                font-size: 16px;
                padding: 4px 8px;
                height: 35px;
                border: 1px solid #000000;
                border-radius: 0;
                background-color: #ffffff;
                border-radius: 8px;

                &:focus {
                  outline: none;
                }
              }

              button {
                height: 35px;
                width: max-content;
                background-color: #fff;
                border: 1px solid #000000;
                color: #000;
                border-radius: 8px;
                font-size: 16px;
                padding: 0 12px;
                margin: 10px auto 35px;

                svg {
                  font-size: 18px;
                  color: #fff;
                }
              }
            }
          }

          .group-images {
            margin-top: 0;

            .artwork-name {
              font-size: 14px;
            }
          }

          .box {
            margin-bottom: 0;
            > div {
              width: 321px;

              .item {
                margin-bottom: 32px;

                .text {
                  font-size: 14px;
                  margin-top: 4px;
                }
              }
            }
          }

          .box--style {
            > div {
              width: 424px;

              .item {
                width: 25%;
                padding: 12px 8px;
                cursor: pointer;

                .text {
                  font-size: 14px;
                  margin-top: 4px;
                }
              }
            }
          }
        }

        //content info modal
        .content-info-modal {
          display: block;
          > div {
            width: 450px;

            .step__title--info {
              padding: 0 50px;
            }

            .group-image-sharp {
              .group-image-sharp__image {
                width: 138px;
                height: 116px;

                .image-1 {
                  height: 62px;
                }
                .image-2 {
                  height: 85px;
                }
              }
              .group-image-sharp__text {
                display: block;
              }
            }

            .group-image-not-use {
              padding: 0 46px;

              .group-image-not-use__item {
                width: 25%;

                .group-image-not-use__item__image {
                  height: 57px;

                  .image-1 {
                    position: absolute;
                  }
                  .image-2 {
                  }
                }
              }
            }

            .btn-i-understand {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              margin-top: 33px;

              > button {
                border: 1px solid #000000;
                background-color: #ffffff;
                color: #000;
                padding: 7px 22px;
                font-size: 16px;

                &:hover {
                  border: 1px solid #000000;
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .bottom {
        height: 100%;
        background-color: #f5f5f5;
        opacity: 0.9;
      }
    }
  }
}
